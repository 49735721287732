import React, { useState, useEffect } from "react";
import EditProfile from "./edit-profile";
import Dashboard from "./dashboard";
import headerImg from "../../../assets/busca-logo.webp";
import Auth from "../../../utils/auth";
import "./agent-portal.css";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../../utils/api";

const BuscaAgentPortal = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [currentPage, setCurrentPage] = useState("Dashboard");
  const [user, setUser] = useState("");
  const [agent, setAgent] = useState("");

  // Run Query Once
  useEffect(() => {
    // User token
    let currentUser;

    if (Auth.loggedIn() !== false) {
      currentUser = Auth.getUser();

      // console.log(currentUser);

      getUserProfile(currentUser.id)
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          // Set the from states with db data
          setUser({
            id: data.id,
            name: data.name,
            email: data.email,
          });
          setAgent(data.agents[0]);

          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      window.parent.postMessage("Login", "*");
    }
  }, []);

  const getCurrentPage = () => {
    switch (currentPage) {
      case "Dashboard":
        return (
          <Dashboard
            setCurrentPage={setCurrentPage}
            user={user}
            agent={agent}
          />
        );
      case "Edit Profile":
        return (
          <EditProfile
            setCurrentPage={setCurrentPage}
            user={user}
            agent={agent}
          />
        );
      default:
        return (
          <Dashboard
            setCurrentPage={setCurrentPage}
            user={user}
            agent={agent}
          />
        );
    }
  };

  if (isLoading) {
    return <>cargando...</>;
  }

  return (
    <div
      className={
        isClosed === true ? "agent-portal busca hidden" : "agent-portal busca"
      }
    >
      <header>
        <div onClick={() => setCurrentPage("Dashboard")}>
          <img
            className="agent-portal-logo"
            alt="Find A Home Logo"
            src={headerImg}
          />
        </div>

        <div
          className={isOpen === false ? "" : "nav-open"}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <p>
            {agent.first_name} {agent.last_name}
          </p>
          <div
            className={
              isOpen === false ? "agent-portal-menu" : "agent-portal-menu open"
            }
          >
            <ul>
              <li
                onClick={() => {
                  Auth.buscaLogout();
                  setIsClosed(true);
                  window.parent.postMessage("Logout", "*");
                }}
              >
                Cerrar sesión
              </li>
            </ul>
          </div>
        </div>
      </header>

      {/* Main */}
      <main>{getCurrentPage()}</main>
    </div>
  );
};

export default BuscaAgentPortal;
