import React, { useState, useEffect } from "react";
import "./admin.css";
import { getUserProfile } from "../../../utils/api";

const Modal = ({ setModalOpen, selectedUser }) => {
  // console.log(selectedUser);
  const [isLoading, setLoading] = useState(true);
  const [agent, setAgent] = useState("");
  const [user, setUser] = useState("");

  // Run Query Once
  useEffect(() => {
    getUserProfile(selectedUser)
      .then((res) => res.json())
      .then((data) => {
        // Set the from states with db data
        setUser({
          id: data.id,
          email: data.email,
        });

        if (!data.agents[0]) {
          setAgent(data.lenders[0]);
        } else {
          setAgent(data.agents[0]);
        }

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <div className="admin-account-modal">
        <div className="admin-modal-content">
          <div className="modal-header">
            <h2>Loading...</h2>
            <span onClick={() => setModalOpen(false)}>&#x2715;</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="admin-account-modal">
      <div className="admin-modal-content">
        <div className="modal-header">
          <h1>
            {agent.first_name} {agent.last_name}
          </h1>
          <span onClick={() => setModalOpen(false)}>&#x2715;</span>
        </div>

        <table
          className={
            agent.primary_language === "Spanish"
              ? "admin-notices busca"
              : "admin-notices"
          }
        >
          <thead>
            <tr>
              <th colSpan="2">Account Information</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>First Name</td>
              <td>{agent.first_name}</td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>{agent.last_name}</td>
            </tr>
            <tr>
              <td>Account Email</td>
              <td>{user.email}</td>
            </tr>
            <tr>
              <td>Agent Profile Email</td>
              <td>{agent.email}</td>
            </tr>
            <tr>
              <td>Phone Number</td>
              <td>{agent.phone_number}</td>
            </tr>
            <tr>
              <td>Languages Spoken</td>
              <td>{agent.languages_spoken}</td>
            </tr>
            <tr>
              <td>Primary Language</td>
              <td>{agent.primary_language}</td>
            </tr>
            <tr>
              <td>Areas Served</td>
              <td>{agent.areas_served}</td>
            </tr>
            <tr>
              <td>License Numbers</td>
              <td>{agent.state_license_numbers}</td>
            </tr>
            <tr>
              <td>Profile Description</td>
              <td>{agent.profile_description}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Modal;
