import React, { useState } from "react";
import { updateUser } from "../../../utils/api";
import editImage from "../../../assets/edit.png";
import imagePath from "../../../utils/image";

const Dashboard = ({ setCurrentPage, user, agent }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showError, setShowError] = useState(false);
  const [email, setEmail] = useState("");

  const displayPhoto = (image) => {
    // console.log(image);
    if (!image) {
      return "https://i.postimg.cc/pXD3gSWd/pngfind-com-placeholder-png-6104451.png";
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `${imagePath}/${image}`;
    }
  };

  const getArea = (agent) => {
    const city = agent.areas_served.split("; ")[0];
    return city;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (email === user.email || email.trim() === "") {
      return;
    } else {
      const userData = {
        email: email,
      };

      updateUser(userData, user.id)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          setShowError(true);
        });
    }
  };

  return (
    <div className="ap-dashboard">
      <main>
        {/* Account Information */}
        <div className="ap-account-details">
          <h1>Account Details</h1>

          {isEditing === true ? (
            <form onSubmit={handleFormSubmit} className="ap-details-input">
              <div>
                <label htmlFor="email" className="loans-form-label">
                  {" "}
                  Email *
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="loans-form-input"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="ap-details-btns">
                <button onClick={() => setIsEditing(false)}>Cancel</button>
                <button className="primary" type="submit">
                  Update
                </button>
              </div>

              {showError ? (
                <div className="message-container">
                  <p className="error-message">
                    Something has gone wrong with your request, please try again
                    later.
                  </p>
                </div>
              ) : (
                <></>
              )}
            </form>
          ) : (
            <p>
              Email: {user.email}{" "}
              <span>
                <img
                  src={editImage}
                  alt="Edit information."
                  onClick={() => setIsEditing(true)}
                />
              </span>
            </p>
          )}
        </div>

        {/* Card Preview */}
        <div>
          {agent.isDeleted === true ? (
            <p className="ap-card-alert">
              This is a preview of how your profile card will be seen by others.
              Once your profile is approved, you’ll be able to edit your
              information by clicking on “Edit Profile”.
            </p>
          ) : (
            <></>
          )}

          <div className="ap-card-conatiner">
            <div className="agent-search-result-card ap-card">
              <div className={!agent.image ? "" : "agent-card-top"}></div>
              <div className="agent-card-content">
                <img src={displayPhoto(agent.image)} alt="Agent Headshot" />
                <h3>
                  {agent.first_name} {agent.last_name}
                </h3>
                <p>{getArea(agent)}</p>
                <p>{agent.email}</p>
                <p>{agent.phone_number}</p>
                <div className="agent-card-btn-container">
                  <div>
                    <button
                      className={
                        agent.isDeleted === false
                          ? "text-shadow agent-card-btn"
                          : "text-shadow agent-card-btn disabled"
                      }
                      onClick={() => setCurrentPage("Edit Profile")}
                    >
                      Edit Profile
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
