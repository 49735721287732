import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

// Pages/Components
import Admin from "./pages/Admin";
import Contact from "./pages/Contact/index";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home/index";
import HomeLoans from "./pages/HomeLoans";
import Login from "./pages/Login";
import OTPValidation from "./pages/OTP";
import Search from "./pages/Search/index";
import SignupSuccess from "./pages/SignupSuccess";

// Find An Agent Feature
import AgentPortal from "./pages/FindAAgent/Portal";
import AgentProfile from "./pages/FindAAgent/Profile";
import AgentSearch from "./pages/FindAAgent/Search";
import AgentSignup from "./pages/FindAAgent/Signup";

// Find a Lender Feature
import LenderPortal from "./pages/FindALender/Portal";
import LenderProfile from "./pages/FindALender/Profile";
import LenderSearch from "./pages/FindALender/Search";
import LenderSignup from "./pages/FindALender/Signup";

// Buscatucasa.com Iframes
import BuscaAgentSignupForm from "./components/IframesBusca/BuscaAgentSignupForm";
import BuscaLogin from "./pages/IframesBusca/BuscaLogin";
import BuscaAgentPortal from "./pages/IframesBusca/BuscaAgentPortal";
import BuscaForgotPassword from "./pages/IframesBusca/BuscaForgotPassword";
import BuscaOTPValidation from "./pages/IframesBusca/BuscaOTP";
import BuscaLenderPortal from "./pages/IframesBusca/BuscaLenderPortal";
import BuscaLenderSignupForm from "./components/IframesBusca/BuscaLenderSignupForm";

// Findahome.com Iframes
import FahAgentSignupForm from "./components/IframesFindahome/FahAgentSignupForm";
import FahLogin from "./pages/IframesFindahome/FahLogin";
import FahAgentPortal from "./pages/IframesFindahome/FahAgentPortal";
import FahForgotPassword from "./pages/IframesFindahome/FahForgotPassword";
import FahOTPValidation from "./pages/IframesFindahome/FahOTP";
import FahLenderPortal from "./pages/IframesFindahome/FahLenderPortal";
import FahAdmin from "./pages/IframesFindahome/FahAdmin";
import FahLenderSignupForm from "./components/IframesFindahome/FahLenderSignupForm";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/home-loans" element={<HomeLoans />} />
        <Route path="/search" element={<Search />} />

        {/* Authentication */}
        <Route path="/user-login" element={<Login />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/otp" element={<OTPValidation />} />

        {/* Agent In the Area Routes */}
        <Route path="/find-an-agent" element={<AgentSearch />} />
        <Route path="/agent-profile/:id" element={<AgentProfile />} />
        <Route path="/agent-signup" element={<AgentSignup />} />
        <Route path="/signup-success" element={<SignupSuccess />} />
        <Route path="/agent-portal" element={<AgentPortal />} />

        {/* Find a Lender Routes */}
        <Route path="/find-a-lender" element={<LenderSearch />} />
        <Route path="/lender-profile/:id" element={<LenderProfile />} />
        <Route path="/lender-signup" element={<LenderSignup />} />
        <Route path="/signup-success" element={<SignupSuccess />} />
        <Route path="/lender-portal" element={<LenderPortal />} />

        {/* Buscatucasa.com Iframes */}
        <Route path="/btcasf2024" element={<BuscaAgentSignupForm />} />
        <Route path="/btclsf2024" element={<BuscaLenderSignupForm />} />
        <Route path="/btcsu2024/user-login" element={<BuscaLogin />} />
        <Route path="/btcapp2024/agent-portal" element={<BuscaAgentPortal />} />
        <Route
          path="/btcsu2024/forgot-password"
          element={<BuscaForgotPassword />}
        />
        <Route path="/btcsu2024/otp" element={<BuscaOTPValidation />} />
        <Route
          path="/btcsu2024/lender-portal"
          element={<BuscaLenderPortal />}
        />

        {/* Findahome.com Iframes */}
        <Route path="/fahasf2024" element={<FahAgentSignupForm />} />
        <Route path="/fahlsf2024" element={<FahLenderSignupForm />} />
        <Route path="/fah2024/user-login" element={<FahLogin />} />
        <Route path="/fah/agent-portal" element={<FahAgentPortal />} />

        <Route
          path="/fah2024/forgot-password"
          element={<FahForgotPassword />}
        />
        <Route path="/fah2024/otp" element={<FahOTPValidation />} />
        <Route path="/fah/lender-portal" element={<FahLenderPortal />} />
        <Route path="/fah-admin" element={<FahAdmin />} />

        {/* Catch All Route */}
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
