import React from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import AgentSignupForm from "../../../components/AgentSignupForm";

const AgentSignup = () => {
  return (
    <div>
      <Header />

      <main>
        <AgentSignupForm />
      </main>

      <Footer />
    </div>
  );
};

export default AgentSignup;
