import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../../utils/api";
import "./forgotpassword.css";

const FahForgotPassword = () => {
  // set states
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      //request to "/api/users/forgot-password"
      const response = await forgotPassword({ email });

      if (response.ok) {
        setErrorMessage("Check your email for the password reset link");
        setTimeout(() => {
          navigate("/fah2024/otp", { state: { email } });
        }, 3000);
      } else {
        console.error("An error occurred while requesting password reset");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <section className="password-reset-page">
      <form className="password-form" onSubmit={handleSubmit}>
        <div className="pw-container">
          <p className="pw-text">
            Enter the email address associated with your account and we'll send
            you a link to update your password.
          </p>
          <div className="password-input">
            <input
              placeholder="Email"
              type="email"
              id="pw-email"
              name="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <button className="password-btn" type="submit">
            Continue
          </button>
          {errorMessage && <p className="message">{errorMessage}</p>}
          <span className="span">
            <p>Dont have an account? </p>
            <p
              onClick={(e) => {
                e.preventDefault();
                window.parent.postMessage("SignUp", "*");
              }}
              className="signup"
            >
              &nbsp; &nbsp;Sign up
            </p>
          </span>
        </div>
      </form>
    </section>
  );
};

export default FahForgotPassword;
