import React, { useState, useEffect } from "react";
import Modal from "./modal";
import "./admin.css";
import {
  updateUser,
  updateAgent,
  updateLender,
  removeNotice,
  getFindahomeNotices,
  getBuscaNotices,
  getCSVFile,
  getLendersCSVFile,
} from "../../../utils/api";

const Dashboard = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [fahNotices, setFahNotices] = useState([]);
  const [buscaNotices, setBuscaNotices] = useState([]);

  const approveUser = (type, agentId, noticeId) => {
    if (type === "Lender") {
      updateLender({ isDeleted: false }, agentId)
        .then((res) => res.json())
        .then((data) => {
          removeNotice(noticeId)
            .then((res) => res.json())
            .then((data) => {
              window.location.reload();
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    } else {
      updateAgent({ isDeleted: false }, agentId)
        .then((res) => res.json())
        .then((data) => {
          removeNotice(noticeId)
            .then((res) => res.json())
            .then((data) => {
              window.location.reload();
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    }
  };

  const blockUser = (userId, noticeId) => {
    updateUser({ isDeleted: true }, userId)
      .then((res) => res.json())
      .then((data) => {
        removeNotice(noticeId)
          .then((res) => res.json())
          .then((data) => {
            window.location.reload();
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const downlowdCSV = () => {
    getCSVFile()
      .then((res) => res.json())
      .then((data) => {
        const fileName = data.fileName;
        window.open(`/${fileName}.csv`);
      })
      .catch((err) => console.log(err));
  };

  const downlowdLenderCSV = () => {
    getLendersCSVFile()
      .then((res) => res.json())
      .then((data) => {
        const fileName = data.fileName;
        window.open(`/${fileName}.csv`);
      })
      .catch((err) => console.log(err));
  };

  // Run Query Once
  useEffect(() => {
    getFindahomeNotices()
      .then((res) => res.json())
      .then((data) => {
        setFahNotices(data);
        getBuscaNotices()
          .then((res) => res.json())
          .then((data) => {
            setBuscaNotices(data);
            setLoading(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <div className="admin-dashboard">
        <div className="admin-table-group">
          <h2>Loading...</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="admin-dashboard">
      {/* Findahome Table */}
      <div className="admin-table-group">
        <h2>Find a Home Agent Pending Accounts</h2>
        <table className="admin-notices">
          <thead>
            <tr>
              <th>Notice</th>
              <th>Date</th>
              <th colSpan="3">Actions</th>
            </tr>
          </thead>

          {!fahNotices[0] ? (
            <tbody>
              {" "}
              <tr>
                <td colSpan="5"> No New Accounts</td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {fahNotices.map((notice) => (
                <tr key={notice.user_id}>
                  <td>{notice.content}</td>
                  <td>{notice.date}</td>
                  <td
                    className="admin-action"
                    onClick={() => {
                      const userId = notice.user_id;
                      setSelectedUser(userId);
                      setModalOpen(!modalOpen);
                    }}
                  >
                    View
                  </td>
                  <td
                    className="admin-action"
                    onClick={() => {
                      const agentId = notice.agent_id;
                      const noticeId = notice.id;

                      if (notice.content.includes("Lender")) {
                        approveUser("Lender", agentId, noticeId);
                      } else {
                        approveUser("Agent", agentId, noticeId);
                      }
                    }}
                  >
                    Approve
                  </td>
                  <td
                    className="admin-action"
                    onClick={() => {
                      const userId = notice.user_id;
                      const noticeId = notice.id;

                      blockUser(userId, noticeId);
                    }}
                  >
                    Block
                  </td>
                </tr>
              ))}{" "}
            </tbody>
          )}
        </table>
      </div>

      {/* Busca Tu Casa Table */}
      <div className="admin-table-group">
        <h2>Busca Tu Casa Pending Accounts</h2>
        <table className="admin-notices busca">
          <thead>
            <tr>
              <th>Notice</th>
              <th>Date</th>
              <th colSpan="3">Actions</th>
            </tr>
          </thead>{" "}
          {!buscaNotices[0] ? (
            <tbody>
              {" "}
              <tr>
                <td colSpan="5"> No New Accounts</td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {buscaNotices.map((notice) => (
                <tr key={notice.user_id}>
                  <td>{notice.content}</td>
                  <td>{notice.date}</td>
                  <td
                    className="admin-action"
                    onClick={() => {
                      const userId = notice.user_id;

                      setSelectedUser(userId);
                      setModalOpen(!modalOpen);
                    }}
                  >
                    View
                  </td>
                  <td
                    className="admin-action"
                    onClick={() => {
                      const agentId = notice.agent_id;
                      const noticeId = notice.id;

                      if (notice.content.includes("Lender")) {
                        approveUser("Lender", agentId, noticeId);
                      } else {
                        approveUser("Agent", agentId, noticeId);
                      }
                    }}
                  >
                    Approve
                  </td>
                  <td
                    className="admin-action"
                    onClick={() => {
                      const userId = notice.user_id;
                      const noticeId = notice.id;

                      blockUser(userId, noticeId);
                    }}
                  >
                    Block
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>

      <div>
        <h2>Download CSV File</h2>
        <div>
          <button onClick={() => downlowdCSV()} className="download-csv-btn">
            Download Agent Data
          </button>
        </div>
        <div>
          <button
            onClick={() => downlowdLenderCSV()}
            className="download-csv-btn"
          >
            Download Lender Data
          </button>
        </div>
      </div>

      {/* Modal */}
      {modalOpen === true ? (
        <Modal setModalOpen={setModalOpen} selectedUser={selectedUser} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Dashboard;
