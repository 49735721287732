import React, { useState, useEffect } from "react";
import { addFile, updateLender } from "../../../utils/api";
import PlacesAutocomplete from "../../../components/PlacesAutocomplete";
import {
  removeFromArr,
  arrayContains,
  replaceSpecialChar,
  formatPhone,
} from "../../../utils/helpers";
import imagePath from "../../../utils/image";

const EditProfile = ({ agent }) => {
  // Agent information/Form State
  const [firstName, setFirstName] = useState(agent.first_name || "");
  const [lastName, setLastName] = useState(agent.last_name || "");
  const [email, setEmail] = useState(agent.email || "");
  const [phoneNumber, setPhoneNumber] = useState(agent.phone_number || "");
  const [languages, setLanguages] = useState(agent.languages_spoken || "");
  const [license, setLicense] = useState(agent.state_license_numbers || "");
  const [company, setcompany] = useState(agent.company || "");
  const [companyNumber, setcompanyNumber] = useState(
    agent.company_number || ""
  );
  const [description, setDescription] = useState(
    agent.profile_description || ""
  );
  const [profileImage, setProfileImage] = useState("");
  const [areas, setAreas] = useState(agent.areas_served.split("; ") || []);
  const [primaryLanguage, setPrimaryLanguage] = useState(
    agent.primary_language || "English"
  );
  const [isBothWebsites, setBothSites] = useState(false);
  const [formattedAddress, setFormattedAddress] = React.useState("");

  // Submit/validation state
  const [showError, setShowError] = useState(false);
  const [emailAlert, setEmailAlert] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const displayPhoto = (image) => {
    if (!image) {
      return "https://i.postimg.cc/pXD3gSWd/pngfind-com-placeholder-png-6104451.png";
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `${imagePath}/${image}`;
    }
  };

  // Check if User can submit
  useEffect(() => {
    if (description.length > 1500) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [description]);

  /*
    Two fetch requests due to different headers types + content:
    1. addFile: uploads the profile image and returns the new file name
    2. addAgent: creates the agent model with form data (+ new file name if applicable)
  */
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = {};

    // Check which fields need to be updated
    if (
      firstName !== "" &&
      agent.first_name !== firstName &&
      lastName !== "" &&
      agent.last_name !== lastName
    ) {
      formData.first_name = firstName;
      formData.last_name = lastName;
      formData.full_name = `${firstName} ${lastName}`;
    } else if (firstName !== "" && agent.first_name !== firstName) {
      formData.first_name = firstName;
      formData.full_name = `${firstName} ${agent.last_name}`;
    } else if (lastName !== "" && agent.last_name !== lastName) {
      formData.last_name = lastName;
      formData.full_name = `${agent.first_name} ${lastName}`;
    }

    if (email !== "" && agent.email !== email) {
      formData.email = email;
    }
    if (phoneNumber !== "" && agent.phone_number !== phoneNumber) {
      formData.phone_number = phoneNumber;
    }
    if (languages !== "" && agent.languages_spoken !== languages) {
      formData.languages_spoken = languages;
    }
    if (areas.join("; ") !== "" && agent.areas_served !== areas.join("; ")) {
      formData.areas_served = areas.join("; ");
    }
    if (license !== "" && agent.state_license_numbers !== license) {
      formData.state_license_numbers = license;
    }
    if (company !== "" && agent.company !== company) {
      formData.company = company;
    }
    if (companyNumber !== "" && agent.company_number !== companyNumber) {
      formData.company_number = companyNumber;
    }
    if (description !== "" && agent.profile_description !== description) {
      formData.profile_description = description;
    }
    if (primaryLanguage !== "" && agent.primary_language !== primaryLanguage) {
      formData.primary_language = primaryLanguage;
    }

    // Check if an image was uploaded
    if (profileImage !== "") {
      // Fetch 1: Upload profile photo
      addFile(profileImage)
        .then((res) => res.json())
        .then((data) => {
          formData.image = data.fileName;

          // Fetch 2: Create the Agent
          if (Object.keys(formData).length === 0) {
            return;
          } else {
            makeAgentUpdates(formData);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowError(true);
        });
    } else {
      if (Object.keys(formData).length === 0) {
        return;
      } else {
        makeAgentUpdates(formData);
      }
    }

    async function makeAgentUpdates(formData) {
      const response = await updateLender(formData, agent.id);

      if (!response.ok) {
        const errorMessage = await response.json();
        const message = errorMessage.errors[0].message;

        // Handle Unique Email Validation Error
        if (message === "email must be unique") {
          console.log("Email Should be Unique");
          setEmailAlert(true);
        } else {
          console.log(message);
          setShowError(true);
        }
      } else {
        window.location.reload();
      }
    }
  };

  const handleImageChange = (e) => {
    console.log(e.target.files[0]);
    // Don't update state if the user clicks "cancel"
    if (!e.target.files[0]) {
      return;
    }

    // Format File for fetch request
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    console.log(formData);
    console.log(...formData);
    setProfileImage(formData);
  };

  return (
    <div className="ap-edit-profile">
      <h1>Edit Profile Information</h1>

      <main className="agent-profile-page edit-profile">
        <div className="agent-col-1">
          <div className="loans-input-flex agent-name-input">
            <div>
              <label htmlFor="firstname" className="loans-form-label">
                First name
              </label>
              <input
                type="text"
                name="firstname"
                id="firstname"
                className="loans-form-input"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="lastname" className="loans-form-label">
                Last name
              </label>
              <input
                type="text"
                name="lastname"
                id="lastname"
                className="loans-form-input"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <img
            src={displayPhoto(agent.image)}
            alt="Agent Headshot"
            className={!agent.image ? "agent-photo" : "agent-photo with-border"}
          />
          <div className="loans-mb-3 agent-signup-photo-upload">
            <label htmlFor="photo" className="loans-form-label hidden">
              Lender Profile Photo Upload *
            </label>
            <input
              type="file"
              name="photo"
              id="photo"
              onChange={handleImageChange}
              accept="image/png, image/jpeg"
            />
          </div>

          <div className="agent-info">
            <h2>Contact Information</h2>
            <div>
              <label htmlFor="email" className="loans-form-label">
                {" "}
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="loans-form-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mt-20">
              <label htmlFor="phone" className="loans-form-label">
                {" "}
                Phone number
              </label>
              <input
                type="text"
                name="phone"
                id="phone"
                className="loans-form-input"
                value={phoneNumber}
                onChange={(e) => {
                  let num = replaceSpecialChar(e.target.value);
                  const phone = formatPhone(num);
                  setPhoneNumber(phone);
                }}
              />
            </div>
          </div>

          <div className="agent-info">
            <h2>Languages</h2>
            <div>
              <label htmlFor="languages" className="loans-form-label hidden">
                {" "}
                Languages Spoken
              </label>
              <input
                type="languages"
                name="languages"
                id="languages"
                className="loans-form-input"
                value={languages}
                onChange={(e) => setLanguages(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="primaryLanguage" className="loans-form-label">
                {" "}
                Website *
              </label>
              <select
                className="agent-form-select"
                id="primaryLanguage"
                name="primaryLanguage"
                value={primaryLanguage}
                onChange={(e) => {
                  if (e.target.value === "Both") {
                    setBothSites(true);
                  } else {
                    setBothSites(false);
                  }
                  setPrimaryLanguage(e.target.value);
                }}
                required
              >
                <option value={"English"}>Find A Home</option>
                <option value={"Spanish"}>Busca Tu Casa</option>
                <option value={"Both"}>Find A Home & Busca Tu Casa</option>
              </select>
            </div>
          </div>

          <div className="agent-info">
            <h2>State License Number</h2>
            <div className="loans-mb-3">
              <label htmlFor="license" className="loans-form-label hidden">
                State License Numbers
              </label>
              <input
                type="text"
                name="license"
                id="license"
                className="loans-form-input"
                value={license}
                onChange={(e) => setLicense(e.target.value)}
              />
            </div>
          </div>

          <div className="agent-info">
            <h2>Company</h2>
            <div className="loans-mb-3">
              <label htmlFor="company" className="loans-form-label hidden">
                Company
              </label>
              <input
                type="text"
                name="company"
                id="company"
                className="loans-form-input"
                value={company}
                onChange={(e) => setcompany(e.target.value)}
              />
            </div>
            <div className="loans-mb-3">
              <label htmlFor="company-number" className="loans-form-label">
                Company Phone Number
              </label>
              <input
                type="text"
                name="company-number"
                id="company-number"
                className="loans-form-input"
                value={companyNumber}
                onChange={(e) => {
                  let num = replaceSpecialChar(e.target.value);
                  const phone = formatPhone(num);
                  setcompanyNumber(phone);
                }}
              />
            </div>
          </div>

          <div className="agent-info">
            <h2>Areas Served</h2>

            {areas.map((area) => (
              <div key={area} id={area} className="area-btn">
                {area}
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    console.log(area);
                    // Remove from State
                    const updatedArr = removeFromArr(areas, area);
                    setAreas(updatedArr);
                    // Hide
                    document.getElementById(`${area}`).style.display = "none";
                  }}
                >
                  X
                </span>
              </div>
            ))}

            <div className="loans-input-flex">
              <div>
                <label htmlFor="city" className="loans-form-label">
                  City, State
                </label>
                <PlacesAutocomplete
                  setFormattedAddress={setFormattedAddress}
                  formattedAddress={formattedAddress}
                />
              </div>
              <div>
                <button
                  className="areas-btn"
                  onClick={(e) => {
                    e.preventDefault();

                    console.log(formattedAddress);

                    // Check for Content
                    const cityTrimmed = formattedAddress.trim();

                    if (cityTrimmed.length !== 0) {
                      // Check for Duplicates
                      const hasDuplicates = arrayContains(
                        areas,
                        formattedAddress
                      );
                      if (hasDuplicates === false) {
                        setAreas([...areas, formattedAddress]);
                      }
                    }
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="agent-col-2 edit-profile-page">
          <div className="loans-mb-3">
            <label htmlFor="description" className="loans-form-label">
              Profile Description
            </label>
            {isBothWebsites ? (
              <p style={{ color: "#536387" }}>
                If you want your profile to be displayed on Busca Tu Casa and
                Find A Home, please make sure to provide both an English and a
                Spanish version of your profile description below!
              </p>
            ) : (
              <></>
            )}
            <textarea
              type="text"
              name="description"
              id="description"
              className="loans-form-input agent-signup-textarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <p
              className={
                canSubmit === false
                  ? "character-count"
                  : "character-count error"
              }
            >
              {description.length} / 1500{" "}
            </p>
          </div>

          <button className="agent-btn update-agent" onClick={handleFormSubmit}>
            Update Information
          </button>

          {showError ? (
            <div className="message-container">
              <p className="error-message">
                Something has gone wrong with your request, please try again
                later.
              </p>
            </div>
          ) : (
            <></>
          )}
          {emailAlert === true ? (
            <div className="message-container">
              <p className="error-message">Email address already in use.</p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </main>
    </div>
  );
};

export default EditProfile;
