import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../../utils/api";
import Auth from "../../../utils/auth";
import "./fahLogin.css";

const FahLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Gather Data for query
    const formData = {
      email: email,
      password: password,
    };

    // Try to Log the User in
    try {
      const response = await loginUser(formData);

      // Handle unsuccesfull login
      if (!response.ok) {
        const { message } = await response.json();
        if (message === "Incorrect password for this account.") {
          setErrorMessage(
            "Your email or password is incorrect, please try again."
          );
        } else if (message === "There is no account found with this email.") {
          setErrorMessage("There is no account found with this email.");
        } else {
          setErrorMessage("Something has gone wrong, please try again.");
        }
      }
      const { token, user_type } = await response.json();

      // If successfull, save token and re-route to their "dashboard"
      Auth.login(token);

      if (user_type === "Super User") {
        window.parent.postMessage("LoggedIn", "*");
        navigate(`/fah-admin`);
      } else if (user_type === "Agent") {
        window.parent.postMessage("LoggedIn", "*");
        navigate(`/fah/agent-portal`);
      } else if (user_type === "Lender") {
        window.parent.postMessage("LoggedIn", "*");
        navigate(`/fah/lender-portal`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <div className="login-page">
        <h1>Welcome back!</h1>
        <p className="login-message">
          This login is for our existing Find a Home Agents and Lenders. If
          you’re new here, you can create a free agent account by visiting the
          Agent Signup or create a free lender account by visiting the Lender
          Signup page.
        </p>

        <form onSubmit={handleFormSubmit}>
          <div className="agent-input-group">
            <label htmlFor="email" className="agent-form-label">
              {" "}
              Email *
            </label>
            <input
              type="email"
              name="lastname"
              id="email"
              placeholder="Email"
              className="agent-form-input"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="agent-input-group">
            <label htmlFor="password" className="agent-form-label">
              {" "}
              Password *
            </label>
            <input
              type="text"
              name="lastname"
              id="password"
              placeholder="Password"
              className="agent-form-input"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <button className="agent-btn" type="submit">
            Login
          </button>

          {/* Display Error Message */}
          {errorMessage ? (
            <p className="error-message">{errorMessage}</p>
          ) : null}
        </form>

        <div className="forgot-password">
          {/* Forgot Password */}
          <p
            className="forgot-password-link"
            onClick={(e) => {
              e.preventDefault();
              window.parent.postMessage("LoggedIn", "*");
              navigate(`/fah2024/forgot-password`);
            }}
          >
            Forgot Password?
          </p>
        </div>
      </div>
    </div>
  );
};

export default FahLogin;
