import React, { useState, useEffect } from "react";
import {
  getAgentProfile,
  addFile,
  updateAgent,
  deleteAgent,
} from "../../../utils/api";
import {
  validateCity,
  removeFromArr,
  arrayContains,
  replaceSpecialChar,
  formatPhone,
} from "../../../utils/helpers";
import PlacesAutocomplete from "../../../components/PlacesAutocomplete";
import imagePath from "../../../utils/image";

const EditAgentProfile = ({ setModalOpen, selectedUser }) => {
  const [agent, setAgent] = useState([]);
  const [isLoading, setLoading] = useState(true);

  // Agent information/Form State
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [languages, setLanguages] = useState();
  const [license, setLicense] = useState();
  const [brokerage, setBrokerage] = useState();
  const [brokerageNumber, setBrokerageNumber] = useState("");
  const [description, setDescription] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [areas, setAreas] = useState([]);
  const [primaryLanguage, setPrimaryLanguage] = useState();
  const [ispremium, setIsPremium] = useState();
  const [checked, setChecked] = useState(false);
  const [isBothWebsites, setBothSites] = useState(false);
  const [formattedAddress, setFormattedAddress] = React.useState("");

  // Submit/validation state
  const [cityAlert, setCityAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [emailAlert, setEmailAlert] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const displayPhoto = (image) => {
    if (!image) {
      return "https://i.postimg.cc/pXD3gSWd/pngfind-com-placeholder-png-6104451.png";
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `${imagePath}/${image}`;
    }
  };

  // Check if User can submit
  useEffect(() => {
    if (description.length > 1500) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [description]);

  useEffect(() => {
    // Get agent Info
    getAgentProfile(selectedUser)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);

        setAgent(data);
        setAreas(data.areas_served.split("; "));
        setFirstName(data.first_name || "");
        setLastName(data.last_name || "");
        setEmail(data.email || "");
        setPhoneNumber(data.phone_number || "");
        setLanguages(data.languages_spoken || "");
        setDescription(data.profile_description || "");
        setIsPremium(data.isPremium || false);
        setLicense(data.state_license_numbers || "");
        setBrokerage(data.brokerage || "");
        setBrokerageNumber(data.brokerage_number || "");
        setPrimaryLanguage(data.primary_language || "");

        setLoading(false);

        if (data.isPremium === true) {
          setChecked(true);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  /*
    Two fetch requests due to different headers types + content:
    1. addFile: uploads the profile image and returns the new file name
    2. addAgent: creates the agent model with form data (+ new file name if applicable)
  */
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = {};

    // Check which fields need to be updated
    if (
      firstName !== "" &&
      agent.first_name !== firstName &&
      lastName !== "" &&
      agent.last_name !== lastName
    ) {
      formData.first_name = firstName;
      formData.last_name = lastName;
      formData.full_name = `${firstName} ${lastName}`;
    } else if (firstName !== "" && agent.first_name !== firstName) {
      formData.first_name = firstName;
      formData.full_name = `${firstName} ${agent.last_name}`;
    } else if (lastName !== "" && agent.last_name !== lastName) {
      formData.last_name = lastName;
      formData.full_name = `${agent.first_name} ${lastName}`;
    }

    if (email !== "" && agent.email !== email) {
      formData.email = email;
    }
    if (phoneNumber !== "" && agent.phone_number !== phoneNumber) {
      formData.phone_number = phoneNumber;
    }
    if (languages !== "" && agent.languages_spoken !== languages) {
      formData.languages_spoken = languages;
    }
    if (areas.join("; ") !== "" && agent.areas_served !== areas.join("; ")) {
      formData.areas_served = areas.join("; ");
    }
    if (license !== "" && agent.state_license_numbers !== license) {
      formData.state_license_numbers = license;
    }
    if (brokerage !== "" && agent.brokerage !== brokerage) {
      formData.brokerage = brokerage;
    }
    if (brokerageNumber !== "" && agent.brokerage_number !== brokerageNumber) {
      formData.brokerage_number = brokerageNumber;
    }
    if (description !== "" && agent.profile_description !== description) {
      formData.profile_description = description;
    }
    if (ispremium !== "" && agent.isPremium !== ispremium) {
      formData.isPremium = ispremium;
    }
    if (primaryLanguage !== "" && agent.primary_language !== primaryLanguage) {
      formData.primary_language = primaryLanguage;
    }

    // Check if an image was uploaded
    if (profileImage !== "") {
      // Fetch 1: Upload profile photo
      addFile(profileImage)
        .then((res) => res.json())
        .then((data) => {
          formData.image = data.fileName;

          // Fetch 2: Create the Agent
          if (Object.keys(formData).length === 0) {
            return;
          } else {
            makeAgentUpdates(formData);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowError(true);
        });
    } else {
      if (Object.keys(formData).length === 0) {
        return;
      } else {
        makeAgentUpdates(formData);
      }
    }

    async function makeAgentUpdates(formData) {
      const response = await updateAgent(formData, agent.id);

      if (!response.ok) {
        const errorMessage = await response.json();
        const message = errorMessage.errors[0].message;

        // Handle Unique Email Validation Error
        if (message === "email must be unique") {
          console.log("Email Should be Unique");
          setEmailAlert(true);
        } else {
          console.log(message);
          setShowError(true);
        }
      } else {
        setShowSuccess(true);
      }
    }
  };

  const handleImageChange = (e) => {
    console.log(e.target.files[0]);
    // Don't update state if the user clicks "cancel"
    if (!e.target.files[0]) {
      return;
    }

    // Format File for fetch request
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    console.log(formData);
    console.log(...formData);
    setProfileImage(formData);
  };

  const handleDelete = (e) => {
    e.preventDefault();

    if (window.confirm("Are you sure you want to delete this agent?")) {
      // user clicked OK
      deleteAgent(agent.id)
        .then((response) => response.json())
        .then((res) => {
          setModalOpen(false);
        })
        .catch((err) => {
          console.log(err);
          setShowError(true);
        });
    } else {
      // user clicked Cancel
      return;
    }
  };

  if (isLoading) {
    return (
      <>
        <div className="min-height"></div>
      </>
    );
  }

  return (
    <div className="admin-account-modal-edit">
      <div className="admin-modal-content-edit">
        <div className="modal-header">
          <h1>Edit Agent</h1>
          <span onClick={() => setModalOpen(false)}>&#x2715;</span>
        </div>

        <div className="edit-btn-container">
          {ispremium === true}
          <input
            type="checkbox"
            id="premium"
            name="isPremium"
            value={ispremium}
            checked={checked}
            onChange={(e) => {
              setChecked(!checked);
              setIsPremium(!checked);
            }}
          />
          <label htmlFor="premium" className="premium-label">
            Mark as Premium Agent
          </label>
        </div>

        <div className="agent-profile-page edit-profile">
          <div className="agent-col-1">
            <div className="loans-input-flex agent-name-input">
              <div>
                <label htmlFor="firstname" className="loans-form-label">
                  First name
                </label>
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  className="loans-form-input"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="lastname" className="loans-form-label">
                  Last name
                </label>
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  className="loans-form-input"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <img
              src={displayPhoto(agent.image)}
              alt="Agent Headshot"
              className={
                !agent.image ? "agent-photo" : "agent-photo with-border"
              }
            />
            <div className="loans-mb-3 agent-signup-photo-upload">
              <label htmlFor="photo" className="loans-form-label hidden">
                Agent Profile Photo Upload *
              </label>
              <input
                type="file"
                name="photo"
                id="photo"
                onChange={handleImageChange}
                accept="image/png, image/jpeg"
              />
            </div>

            <div className="agent-info">
              <h2>Contact Information</h2>
              <div>
                <label htmlFor="email" className="loans-form-label">
                  {" "}
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="loans-form-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mt-20">
                <label htmlFor="phone" className="loans-form-label">
                  {" "}
                  Phone number
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  className="loans-form-input"
                  value={phoneNumber}
                  onChange={(e) => {
                    let num = replaceSpecialChar(e.target.value);
                    const phone = formatPhone(num);
                    setPhoneNumber(phone);
                  }}
                />
              </div>
            </div>

            <div className="agent-info">
              <h2>Languages</h2>
              <div>
                <label htmlFor="languages" className="loans-form-label hidden">
                  {" "}
                  Languages Spoken
                </label>
                <input
                  type="languages"
                  name="languages"
                  id="languages"
                  className="loans-form-input"
                  value={languages}
                  onChange={(e) => setLanguages(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="primaryLanguage" className="loans-form-label">
                  {" "}
                  Website *
                </label>
                <select
                  className="agent-form-select"
                  id="primaryLanguage"
                  name="primaryLanguage"
                  value={primaryLanguage}
                  onChange={(e) => {
                    if (e.target.value === "Both") {
                      setBothSites(true);
                    } else {
                      setBothSites(false);
                    }
                    setPrimaryLanguage(e.target.value);
                  }}
                  required
                >
                  <option value={"English"}>Find A Home</option>
                  <option value={"Spanish"}>Busca Tu Casa</option>
                  <option value={"Both"}>Find A Home & Busca Tu Casa</option>
                </select>
              </div>
            </div>

            <div className="agent-info">
              <h2>State License Number</h2>
              <div className="loans-mb-3">
                <label htmlFor="license" className="loans-form-label hidden">
                  State License Numbers
                </label>
                <input
                  type="text"
                  name="license"
                  id="license"
                  className="loans-form-input"
                  value={license}
                  onChange={(e) => setLicense(e.target.value)}
                />
              </div>
            </div>

            <div className="agent-info">
              <h2>Brokerage</h2>
              <div className="loans-mb-3">
                <label htmlFor="brokerage" className="loans-form-label hidden">
                  Brokerage
                </label>
                <input
                  type="text"
                  name="brokerage"
                  id="brokerage"
                  className="loans-form-input"
                  value={brokerage}
                  onChange={(e) => setBrokerage(e.target.value)}
                />
              </div>
              <div className="loans-mb-3">
                <label htmlFor="brokerage-number" className="loans-form-label">
                  Brokerage Phone Number
                </label>
                <input
                  type="text"
                  name="brokerage-number"
                  id="brokerage-number"
                  className="loans-form-input"
                  value={brokerageNumber}
                  onChange={(e) => {
                    let num = replaceSpecialChar(e.target.value);
                    const phone = formatPhone(num);
                    setBrokerageNumber(phone);
                  }}
                />
              </div>
            </div>

            <div className="agent-info">
              <h2>Areas Served</h2>

              {areas.map((area) => (
                <div key={area} id={area} className="area-btn">
                  {area}
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      console.log(area);
                      // Remove from State
                      const updatedArr = removeFromArr(areas, area);
                      setAreas(updatedArr);
                      // Hide
                      document.getElementById(`${area}`).style.display = "none";
                    }}
                  >
                    X
                  </span>
                </div>
              ))}

              <div className="loans-input-flex">
                <div>
                  <label htmlFor="city" className="loans-form-label">
                    City, State
                  </label>
                  <PlacesAutocomplete
                    setFormattedAddress={setFormattedAddress}
                    formattedAddress={formattedAddress}
                  />
                </div>
                <div>
                  <button
                    className="areas-btn"
                    onClick={(e) => {
                      e.preventDefault();

                      console.log(formattedAddress);

                      // Check for Content
                      const cityTrimmed = formattedAddress.trim();

                      if (cityTrimmed.length !== 0) {
                        // Check for Duplicates
                        const hasDuplicates = arrayContains(
                          areas,
                          formattedAddress
                        );
                        if (hasDuplicates === false) {
                          setAreas([...areas, formattedAddress]);
                        }
                      }
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>

            <button className="delete-agent-btn" onClick={handleDelete}>
              Delete Agent
            </button>
          </div>
          <div className="agent-col-2 edit-profile-page">
            <div className="loans-mb-3">
              <label htmlFor="description" className="loans-form-label">
                Profile Description
              </label>
              {isBothWebsites ? (
                <p style={{ color: "#536387" }}>
                  If you want your profile to be displayed on Busca Tu Casa and
                  Find A Home, please make sure to provide both an English and a
                  Spanish version of your profile description below!
                </p>
              ) : (
                <></>
              )}
              <textarea
                type="text"
                name="description"
                id="description"
                className="loans-form-input agent-signup-textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <p
                className={
                  canSubmit === false
                    ? "character-count"
                    : "character-count error"
                }
              >
                {description.length} / 1500{" "}
              </p>
            </div>

            <button
              className="agent-btn update-agent"
              onClick={handleFormSubmit}
            >
              Update Agent Information
            </button>

            {showError ? (
              <div className="message-container">
                <p className="error-message">
                  Something has gone wrong with your request, please try again
                  later.
                </p>
              </div>
            ) : (
              <></>
            )}
            {emailAlert === true ? (
              <div className="message-container">
                <p className="error-message">Email address already in use.</p>
              </div>
            ) : (
              <></>
            )}
            {showSuccess === true ? (
              <div className="message-container">
                <p className="">Profile Succesfully Updated!</p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAgentProfile;
