import React from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./signupSuccess.css";

const SignupSuccess = () => {
  return (
    <>
      <Header />

      <div className="signup-success-page">
        <h1>Success!</h1>

        <p>
          Thanks for signing up! We’re currently reviewing your profile
          information for approval. To access and manage your account, visit the{" "}
          <a href="/user-login" className="link">
            User Login
          </a>{" "}
          page.
        </p>

        <a href="/">
          <button>Back to Home</button>
        </a>
      </div>

      <Footer />
    </>
  );
};

export default SignupSuccess;
